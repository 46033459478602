import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import Header from './Header/Header'
import Page from '../pages/Page'
import SEO from './SEO/SEO'
import LoaderSpinner from './LoaderSpinner/LoaderSpinner'

class Layout extends Component {
  render() {
    const {
      user,
      authenticating,
      authenticated,
      showLoginScreen,
      page,
    } = this.props

    if(!page) return null

    const {slug} = page
    const isFrontPage = slug === '/'

    const publicDomain = page?.publicDomain


    const firstSection = page?.content ? page?.content[0] : null
    const firstSectionType = firstSection?.sys?.contentType?.sys?.id || null
    const firstIsHero = firstSectionType === 'hero'

    return (
      <>
        <SEO page={page}/>

        {!isFrontPage &&
          <Header {...this.props} firstIsHero={firstIsHero}/>
        }

        <main>

          {(publicDomain || authenticated) &&
          <Switch>
            <Route
              path="/:slug?"
              render={props => <Page {...props} page={page} user={user}/>}
            />
          </Switch>
          }
        </main>

        {!showLoginScreen && authenticating &&
        <LoaderSpinner/>
        }

      </>
    )
  }
}

export default Layout
