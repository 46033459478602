import React from 'react'
import styles from './PropertyContacts.module.css'

export default function PropertyContacts({data}) {
  const {
    assetManager,
    dealOwner,
  } = data

  const {
    name,
    phone,
    email,
  } = assetManager

  return (
    <section className={styles.PropertyContacts}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-4">
            <div className={styles.contact}>
              {name && <h6>Asset Manager</h6>}
              {name && <span>{name}</span>}
              {phone && <a href={`tel:${phone}`}>{phone}</a>}
              {email && <a href={`mailto:${email}`}>{email}</a>}
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-4">
            <div className={styles.contact}>
              {dealOwner.name && <h6>Deal Owner</h6>}
              {dealOwner.name && <span>{dealOwner.name}</span>}
              {phone && <a href={`tel:${dealOwner.phone}`}>{dealOwner.phone}</a>}
              {dealOwner.email && <a href={`mailto:${dealOwner.email}`}>{dealOwner.email}</a>}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
