import React, {Component} from 'react'
import styles from './PropertiesMapFilters.module.css'
import PropertiesMapFilterList from '../PropertiesMapFilterList/PropertiesMapFilterList'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import {analyticsEvent} from '../../services/service-analytics'

class PropertiesMapFilters extends Component {
  constructor(props) {
    super(props)

    this.onToggleFilters = this.onToggleFilters.bind(this)

    this.state = {
      show: false,
      countries: null,
      funds: null,
      platforms: null,
      statuses: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.propertiesFormatted && this.props.propertiesFormatted) {
      this.loadData()
    }
  }

  async loadData() {
    const {propertiesFormatted} = this.props
    if(!propertiesFormatted) return
    const countries = [...new Set(propertiesFormatted.filter(p => p.country).map(p => p.country))]
    const funds = [...new Set(propertiesFormatted.filter(p => p.fund).map(p => p.fund))]
    const platforms = [...new Set(propertiesFormatted.filter(p => p.platform && p.platform !== 'N/A').map(p => p.platform))]
    const statuses = [...new Set(propertiesFormatted.filter(p => p.status).map(p => p.status))]

    this.setState({
      countries: countries.sort(),
      funds: funds.sort(),
      platforms: platforms.sort(),
      statuses: statuses.sort()
    })
  }

  onToggleFilters() {
    this.setState(prevState => {
      return {
        show: !prevState.show
      }
    }, () => {
      analyticsEvent('properties_map', 'map toggle filters', this.state.show ? 'open' : 'close')
    })
  }

  render() {
    const {
      propertiesFiltered,
      onSearchInputChange,
      onSliderChange,
      onFilterChange,
      onResetFilters,
      onClearSearch,
      filtersAll,
      filterIRR,
      IRRMin,
      IRRMax,
      filterMoC,
      MoCMin,
      MoCMax,
      query,
    } = this.props

    // console.log('filtersAll', filtersAll)

    const numPropertiesFiltered = propertiesFiltered.length
    const numFilters = filtersAll.map(f => f.filters).flat().length
    // console.log('numFilters', numFilters)

    const {
      show,
      countries,
      funds,
      platforms,
      statuses,
    } = this.state

    const hasFilters = numFilters !== 0

    return (
      <>
        <button
          className={show ? styles.buttonFiltersOpen : styles.buttonFilter}
          onClick={this.onToggleFilters}
        >
          <span className={styles.buttonBg}/>

          {show &&
            <span>
              <span>Filters</span>
              <span className={styles.numFilters}>{hasFilters ? `(${numFilters})` : ''}</span>
            </span>
          }

          <div className={show ? styles.iconBgClose : styles.iconBg}>
            <Svg id={show ? ICONS.plus : ICONS.filters}/>
            {!show && hasFilters &&
              <span className={styles.numFiltersIcon}>
                <small>{numFilters}</small>
              </span>
            }
          </div>
        </button>

        {show &&
          <button
            className={`button link ${hasFilters && show ? styles.buttonResetShow : styles.buttonReset}`}
            onClick={onResetFilters}
          >
            Clear all
          </button>
      }

      {!show &&
        <div className={styles.inputSearchWrapper}>
          <Svg id={ICONS.search} className={styles.iconSearch}/>
          <input
            type="text"
            name="search_map"
            placeholder={`Search ${numPropertiesFiltered} properties`}
            value={query}
            onChange={onSearchInputChange}
            className={styles.inputSearch}
          />

          {query &&
            <button
              className={styles.buttonClearSearch}
              onClick={onClearSearch}
            >
              <Svg id={ICONS.plus} className={styles.iconClearSearch}/>
            </button>
          }
        </div>
      }

        <div className={show ? styles.show : styles.PropertiesMapFilters}>
          <div className={styles.scrollContainer}>
            <div className={styles.content}>

              <div className={styles.rangeWrapper}>
                <h6>Return (IRR) {filterIRR > IRRMin ? `> ${filterIRR}%` : ''}</h6>
                <input
                  type="range"
                  min={IRRMin}
                  max={IRRMax}
                  step={1}
                  value={filterIRR}
                  onChange={event => {
                    onSliderChange('filterIRR', event.target.value)
                  }}
                />
              </div>
              <div className={styles.rangeWrapper}>
                <h6>Return (MoC) {filterMoC > MoCMin ? `> ${filterMoC}x` : ''}</h6>
                <input
                  type="range"
                  min={MoCMin}
                  max={MoCMax}
                  step={1}
                  value={filterMoC}
                  onChange={event => {
                    onSliderChange('filterMoC', event.target.value)
                  }}
                />
              </div>

              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={countries}
                field="country"
                filterId="filtersCountries"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={funds}
                field="fund"
                filterId="filtersFunds"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={platforms}
                field="platform"
                filterId="filtersPlatforms"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={statuses}
                field="status"
                filterId="filtersStatuses"
                onFilterChange={onFilterChange}
              />

            </div>
          </div>

          <button
            className={styles.showingNumProps}
            onClick={this.onToggleFilters}
          >
            Show {numPropertiesFiltered} properties
          </button>
        </div>
      </>
    )
  }
}

export default PropertiesMapFilters
