import React from 'react'
import styles from './Property.module.css'
import PropertyHeader from '../PropertyHeader/PropertyHeader'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import PropertyKeyFigures from "../PropertyKeyFigures/PropertyKeyFigures"
import PropertyContacts from "../PropertyContacts/PropertyContacts"

export default function Property({data}) {
  const {
    id,
  } = data

  // console.log('Property', data)

  return (
    <Router>
      <div id={id} className={styles.Property}>
        <PropertyHeader data={data}/>
        <PropertyKeyFigures data={data}/>
        <PropertyContacts data={data}/>
      </div>
    </Router>
  )
}
