import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import styles from './PropertiesMap.module.css'
import PropertiesMapFilters from '../PropertiesMapFilters/PropertiesMapFilters'
import {analyticsEvent} from '../../services/service-analytics'
import propertiesJSON from 'assets/data/properties.json'
import Map from '../Map/Map'
import ModalProperty from "../ModalProperty/ModalProperty"
import {getStatus, getUniqueLat} from "../../services/service-utils"
import {getUrlParams} from "../../services/service-url-search-params"

class PropertiesMap extends Component {
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.onSearchInputChange = this.onSearchInputChange.bind(this)
    this.onResetFilters = this.onResetFilters.bind(this)
    this.onClearSearch = this.onClearSearch.bind(this)
    this.onSliderChange = this.onSliderChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.formatProperties = this.formatProperties.bind(this)
    this.onButtonVisit = this.onButtonVisit.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)

    /*const certs = [...new Set(propertiesJSON
      .filter(p => p.exitDate)
      .map(p => p.exitDate))]
    console.log('certs', certs.forEach(c => console.log(c)))*/

    const IRRs = propertiesJSON.filter(p => p.realizedIRR).map(p => Number(p.realizedIRR))
    const IRRMin = Math.floor(Math.min(...IRRs) - 2)
    const IRRMax = Math.ceil(Math.max(...IRRs) + 2)

    const MoCs = propertiesJSON.filter(p => p.realizedMoC).map(p => Number(p.realizedMoC))
    const MoCMin = Math.floor(Math.min(...MoCs) - 2)
    const MoCMax = Math.ceil(Math.max(...MoCs) + 2)

    this.state = {
      screenHeight: '100vh',
      propertiesFormatted: null,
      propertiesFiltered: [],
      query: '',
      filtersCountries: [],
      filtersFunds: [],
      filtersPlatforms: [],
      filtersStatuses: [],
      IRRMin: IRRMin,
      IRRMax: IRRMax,
      filterIRR: IRRMin,
      MoCMin: MoCMin,
      MoCMax: MoCMax,
      filterMoC: MoCMin,
      centerLatLng: {
        lat: 56.03314,
        lng: 12.18622
      },
      modalId: null,
    }
  }

  componentDidMount() {
    this.formatProperties()
    window.addEventListener("resize", this.onResize)
    setTimeout(this.onResize, 0)

    const {location} = this.props
    const currentSearchParams = getUrlParams(location)
    const {property} = currentSearchParams || {}
    if(property) {
      this.setState({modalId: property})
    }

    // TEST:
    /*
    setTimeout(() => {
      this.setState({query: 'aug'}, this.filterProperties)
    }, 500)
    */
  }

  onResize() {
    const {innerHeight} = window
    this.setState({screenHeight: `${innerHeight}px`})
  }

  onSearchInputChange(event) {
    analyticsEvent('properties_map', 'map search input', event.target.value)
    this.setState({query: event.target.value}, this.filterProperties)
  }

  onResetFilters() {
    const {propertiesFormatted, IRRMin, MoCMin} = this.state
    this.setState({
      propertiesFiltered: propertiesFormatted,
      filtersCountries: [],
      filtersFunds: [],
      filtersPlatforms: [],
      filtersStatuses: [],
      filterIRR: IRRMin,
      filterMoC: MoCMin,
    }, () => {
      analyticsEvent('properties_map', 'map reset filters')
    })
  }

  onClearSearch() {
    this.setState({query: ''}, this.filterProperties)
  }

  onSliderChange(filterId, value) {
    // console.log('value', value)
    this.setState(prevState => {
      return {
        [filterId]: value
      }
    }, this.filterProperties)
  }

  onFilterChange(filterId, value, addRemoveBoolean) {
    // console.log('onFilterChange', {filterId, value, addRemoveBoolean})
    if(addRemoveBoolean) analyticsEvent('properties_map', 'map filter checked', value)

    this.setState(prevState => {
      const prevArray = prevState[filterId]
      const newArray = addRemoveBoolean ? [...prevArray, value] : prevArray.filter(f => f !== value)
      return {
        [filterId]: newArray
      }
    }, this.filterProperties)
  }

  filterProperties() {
    const {
      propertiesFormatted,
      query,
      filtersCountries,
      filtersFunds,
      filtersPlatforms,
      filtersStatuses,
      filterIRR,
      filterMoC,
    } = this.state

    this.setState(() => {
      const filtered = propertiesFormatted.filter(p => {
        const {
          name,
          platform,
          address,
          city,
          municipality,
          country,
          status,
          fund,
        } = p

        const q = query.toLowerCase()
        const realizedIRR = Number(p.realizedIRR)
        const realizedMoC = Number(p.realizedMoC)
        // console.log('filterIRR', filterIRR, 'realizedIRR', realizedIRR)

        if(
          query.length > 0
          && (!name || !name.toLowerCase().includes(q))
          && (!platform || !platform.toLowerCase().includes(q))
          && (!address || !address.toLowerCase().includes(q))
          && (!city || !city.toLowerCase().includes(q))
          && (!municipality || !municipality.toLowerCase().includes(q))
          && (!country || !country.toLowerCase().includes(q))
        ) return false

        if(filtersCountries.length > 0 && !filtersCountries.includes(country)) return false
        if(filtersFunds.length > 0 && !filtersFunds.includes(fund)) return false
        if(filtersPlatforms.length > 0 && !filtersPlatforms.includes(platform)) return false
        if(filtersStatuses.length > 0 && !filtersStatuses.includes(status)) return false
        if((realizedIRR < filterIRR)) return false
        if((realizedMoC < filterMoC)) return false
        return true
      })

      return {
        propertiesFiltered: filtered
      }
    })
  }

  formatProperties() {
    const tempArray = []
    const formatted = propertiesJSON
      .filter(p => Boolean(p.latitude && p.longitude && p.name))
      .map((p, idx) => {
        const {latitude, longitude} = p
        const isDuplicate = tempArray.some(pt => pt.latitude === latitude && pt.longitude === longitude)
        if(isDuplicate) {
          const newLat = getUniqueLat(tempArray, latitude, longitude)
          const alteredP = {...p, ...{latitude: newLat}}
          tempArray[idx] = alteredP
          return alteredP
        }
        tempArray[idx] = p
        return p
      })
      .map(p => {
        const {id, exitDate, status} = p
        const imageUrl = `/assets/map/${id}.jpg`
        const statusOverride = getStatus(exitDate, status)

        return {...p, ...{
          image_url: imageUrl,
          status: statusOverride
        }}
      })

    this.setState({
      propertiesFormatted: formatted,
      propertiesFiltered: formatted,
    })
  }

  onButtonVisit(event) {
    event.preventDefault()

    const {dataset} = event.target
    const {property} = dataset
    const propertyParsed = JSON.parse(property)

    const {
      id,
      name,
    } = propertyParsed

    analyticsEvent('properties_map', 'map take tour', name)

    this.setState({
      modalId: (id || encodeURIComponent(name.toLowerCase()))
    })
  }

  onCloseModal() {
    this.setState({
      modalId: null
    })
  }

  render() {
    const {id, user} = this.props

    const {
      screenHeight,
      propertiesFormatted,
      propertiesFiltered,
      query,
      filtersCountries,
      filtersFunds,
      filtersPlatforms,
      filtersStatuses,
      IRRMin,
      IRRMax,
      filterIRR,
      MoCMin,
      MoCMax,
      filterMoC,
      centerLatLng,
      modalId,
    } = this.state

    if(!propertiesFormatted) return null

    const filtersIRR = filterIRR > IRRMin ? [1] : []
    const filtersMoC = filterMoC > MoCMin ? [1] : []

    const filtersAll = [
      {id: 'country', filters: filtersCountries},
      {id: 'fund', filters: filtersFunds},
      {id: 'platform', filters: filtersPlatforms},
      {id: 'status', filters: filtersStatuses},
      {id: 'irr', filters: filtersIRR},
      {id: 'moc', filters: filtersMoC},
    ]

    const hasFiltersOn = propertiesFormatted.length !== propertiesFiltered.length

    const styleMap = {
      height: screenHeight
    }

    return (
      <>
        <section id={id} className={styles.PropertiesMap} style={styleMap}>
          <div className={styles.mapContainer}>
            <Map
              propertiesFiltered={propertiesFiltered}
              centerLatLng={centerLatLng}
              zoom={5}
              enableInfo={true}
              enableFullscreen={false}
              onInfoCTA={this.onButtonVisit}
              query={query}
              autoFitBounds={hasFiltersOn}
            />
            <PropertiesMapFilters
              propertiesFormatted={propertiesFormatted}
              propertiesFiltered={propertiesFiltered}
              filtersAll={filtersAll}
              filterIRR={filterIRR}
              IRRMin={IRRMin}
              IRRMax={IRRMax}
              filterMoC={filterMoC}
              MoCMin={MoCMin}
              MoCMax={MoCMax}
              query={query}
              onSearchInputChange={this.onSearchInputChange}
              onFilterChange={this.onFilterChange}
              onSliderChange={this.onSliderChange}
              onResetFilters={this.onResetFilters}
              onClearSearch={this.onClearSearch}
            />
          </div>
        </section>

        {modalId &&
          <ModalProperty
            propertiesFormatted={propertiesFormatted}
            propertyId={modalId}
            user={user}
            onClose={this.onCloseModal}
          />
        }
      </>
    )
  }
}

export default withRouter(PropertiesMap)
