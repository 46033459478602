const ICONS = Object.freeze({
  arrowLeft: 'arrow-left',
  checkmark: 'checkmark',
  info: 'info',
  file: 'file',
  house: 'house',
  navLeftSmall: 'nav-left-small',
  navRightSmall: 'nav-right-small',
  navLeft: 'nav-left',
  navRight: 'nav-right',
  navUp: 'nav-up',
  navDown: 'nav-down',
  nrep_logo: 'nrep-logo',
  pin: 'pin',
  play: 'play',
  plus: 'plus',
  filters: 'filters',
  search: 'search',
})

export default ICONS
