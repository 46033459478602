import React, {Component} from 'react' // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom'
import {withRouter} from 'react-router-dom'
import {setLockedViewport} from '../../services/service-utils'
import {getUrlParams, pushUrlParams} from "../../services/service-url-search-params"

const modalRoot = document.getElementById('modal')

class Modal extends Component {
  constructor(props) {
    super(props)

    this.onKeyPress = this.onKeyPress.bind(this)

    this.el = document.createElement('div')
  }

  componentDidMount() {
    const {history, location, propertyId} = this.props
    const currentSearchParams = getUrlParams(location)

    const newSearchParams = {
      ...{},
      ...currentSearchParams,
      ...{property: propertyId}
    }

    // console.log('onmount', newSearchParams)

    pushUrlParams(history, newSearchParams)

    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el)
    setLockedViewport(true)

    document.addEventListener('keyup', this.onKeyPress)
  }

  componentDidUpdate(prevProps) {
    const {location, onClose} = this.props
    const prevSearchParams = getUrlParams(prevProps.location)
    const currentSearchParams = getUrlParams(location)
    const {property} = currentSearchParams

    if(prevSearchParams.property && !property) {
      if(onClose) onClose()
    }
  }

  componentWillUnmount() {
    const {history, location} = this.props
    const currentSearchParams = getUrlParams(location)

    const newSearchParams = {
      ...{},
      ...currentSearchParams,
      ...{property: null}
    }

    pushUrlParams(history, newSearchParams)

    setLockedViewport(false)
    modalRoot.removeChild(this.el)
  }

  onKeyPress(event) {
    if(event.key === 'Escape') {
      const {onClose} = this.props
      if(onClose) onClose()
    }
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    )
  }
}

export default withRouter(Modal)
