export const isDevEnvironment = Boolean(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

export const getViewPortWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}
export const getViewPortHeight = () => {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
}
export const isMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches
}
export const isDesktop = () => {
  return window.matchMedia("(min-width: 1024px)").matches
}
export const isHD = () => {
  return window.matchMedia("(min-width: 1200px)").matches
}

export const getStatus = (exitDate, status) => {
  const expired = exitDate && new Date(exitDate) < new Date()
  return expired ? 'Exited' : status
}

export const getUniqueLat = (array, lat, lng) => {
  const exists = array.some(p => p.latitude === lat && p.longitude === lng)
  if(exists) {
    // const newLng = (Number(lng) + .0001).toFixed(5)
    const newLat = (Number(lat) + .00002).toFixed(5)
    return getUniqueLat(array, newLat, lng)
  }
  return lat
}

export const scrollToElement = (element, offsetY = 0) => {
  if(element) {
    const offset = element.getBoundingClientRect()
    // console.log('element', window.scrollY)
    window.scrollTo({
      top: offset.top + window.scrollY + offsetY,
      behavior: "smooth"
    })
  }
}

let tempScrollPosition = 0
export const setLockedViewport = bool => {
  if(bool) {
    tempScrollPosition = document.documentElement.scrollTop
    document.body.style.top = `-${tempScrollPosition}px`
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.height = '100vh'
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.top = ''
    document.body.style.position = ''
    document.body.style.width = ''
    document.body.style.height = ''
    document.body.style.overflow = ''
    document.documentElement.scrollTop = tempScrollPosition
  }
}
