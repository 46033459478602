import React from 'react'
import styles from './PropertyKeyFigures.module.css'
import PropertyKeyFiguresItem from '../PropertyKeyFiguresItem/PropertyKeyFiguresItem'

export default function PropertyKeyFigures({data}) {
  const {
    activeFromYear,
    investmentCost,
    equity,
    netIRR,
    netMoC,
    realizedIRR,
    realizedMoC,
    tagline,
    fund,
    sustainability,
    /*linkToIC,*/
  } = data

  const award = sustainability && sustainability !== 'No certificate' ? sustainability : null
  const paths = {
    'Breeam - Excellent': null,
    'Breeam - Very Good': 'sustainability-breeam-very-good.png',
    'Breeam - Good': null,
    'Breeam - Pass': null,
    'DGNB - Silver': 'sustainability-dgnb-silver.png',
    'DGNB - Gold': 'sustainability-dgnb-gold.png',
    'DGNB - Platinum': 'sustainability-dgnb-platinum.png',
    'Leed - Gold': 'sustainability-leed-gold.png',
    'Greenbuilding': 'sustainability-green-building.png',
    'Nordic Swan Ecolabel': 'sustainability-nordic-swan-ecolabel.png',
  }
  const awardPath = award ? paths[award] : null

  return (
    <section className={styles.sectionKeyFigures}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">

            <div className={styles.logosSustain}>
              <div>
                {awardPath &&
                  <img src={`/assets/images/${awardPath}`} alt={award}/>
                }
                {!awardPath && award &&
                  <>
                    <span>Sustainability</span>
                    <br/>
                    <strong>{award}</strong>
                  </>
                }
              </div>
            </div>

            {tagline &&
              <div className={styles.desc}>
                {tagline}
              </div>
            }

          </div>
          <div className="col-xs-12">

            <div className={styles.PropertyKeyFigures}>
              {netIRR &&
                <PropertyKeyFiguresItem
                  title="Target Net IRR"
                  description={`${netIRR}%`}
                />
              }
              {netMoC &&
                <PropertyKeyFiguresItem
                  title="Target Net MOC"
                  description={`${netMoC}x`}
                />
              }
              {realizedIRR &&
                <PropertyKeyFiguresItem
                  title="Realized IRR"
                  description={`${realizedIRR}%`}
                />
              }
              {realizedMoC &&
                <PropertyKeyFiguresItem
                  title="Realized MOC"
                  description={`${realizedMoC}x`}
                />
              }
              {investmentCost &&
                <PropertyKeyFiguresItem
                  title="Investment Cost"
                  description={`€ ${Number(investmentCost / 1000000).toFixed(1).toLocaleString()}m`}
                />
              }
              {equity &&
                <PropertyKeyFiguresItem
                  title="Equity"
                  description={`€ ${Number(equity / 1000000).toFixed(1).toLocaleString()}m`}
                />
              }
              {fund &&
                <PropertyKeyFiguresItem
                  title="Fund"
                  description={fund}
                />
              }
              {activeFromYear &&
                <PropertyKeyFiguresItem
                  title="Active from year"
                  description={activeFromYear}
                />
              }
            </div>


            {/*{linkToIC &&
              <a
                href={linkToIC}
                target="_blank"
                rel="noopener noreferrer"
                className="button blue"
                style={{marginTop: '2rem'}}
              >
                GET IC MATERIAL
              </a>
            }*/}


          </div>
        </div>
      </div>
    </section>
  )
}
