import React from 'react'
import styles from './InfoWindow.module.css'
import {getStatus} from "../../services/service-utils"

export default function InfoWindow({property}) {
  const {
    fund,
    image_url,
    name,
    platform,
    status,
    exitDate,
  } = property

  const statusOverride = getStatus(exitDate, status)

  return (
    <div className={image_url ? styles.InfoWindowImage : styles.InfoWindow}>

      {image_url &&
      <img
        src={image_url}
        alt=""
        className={styles.image}
      />
      }

      <div className={styles.content}>
        {name &&
        <span>{name}</span>
        }
        {fund &&
        <span>{fund}</span>
        }
        {platform &&
        <span>{platform}</span>
        }
        {statusOverride &&
        <small className={styles.status}>
           <span className={`dot ${statusOverride}`}/>
           <span className={styles.label}>{statusOverride}</span>
        </small>
        }

        <button
          className="button sm primary"
          data-property={JSON.stringify(property)}
        >
          VISIT
        </button>

      </div>
    </div>
  )
}
