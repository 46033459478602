import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'
import styles from './Map.module.css'
import {Loader} from '@googlemaps/js-api-loader'
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner'
 import  MarkerClusterer  from "@google/markerclustererplus"; 
import InfoWindow from './InfoWindow'
import {analyticsEvent} from '../../services/service-analytics'
import {GOOGLE_API_KEY, MAP_STYLES} from "../../constants/const-map-styles"

class Map extends Component {
  constructor(props) {
    super(props)

    this.loadGoogleMapsApi = this.loadGoogleMapsApi.bind(this)
    this.setupInfoWindowListeners = this.setupInfoWindowListeners.bind(this)
    this.showHideMarkers = this.showHideMarkers.bind(this)
    this.renderMarkers = this.renderMarkers.bind(this)
    this.renderClusters = this.renderClusters.bind(this)
    this.renderMap = this.renderMap.bind(this)
    // this.onMarkerHover = this.onMarkerHover.bind(this)
    this.onMarkerClick = this.onMarkerClick.bind(this)

    this.google = null
    this.map = null
    this.bounds = null
    this.markers = []
    this.clusterer = null
  }

  componentDidMount() {
    this.loadGoogleMapsApi()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {propertiesFiltered} = this.props

    if(propertiesFiltered && propertiesFiltered !== prevProps.propertiesFiltered) {
      this.renderMap()
    }
  }

  loadGoogleMapsApi() {
    const loader = new Loader({
      apiKey: GOOGLE_API_KEY,
      version: 'weekly',
      libraries: ['places']
    })

    loader
      .load()
      .then(google => {
        this.google = google
        this.renderMap()
      })
      .catch(error => {
        console.log('Map loader error', error)
      })
  }

  setupInfoWindowListeners() {
    const {onInfoCTA} = this.props
    if(!onInfoCTA) return
    const buttons = [...document.querySelectorAll('[data-property]')]
    if(buttons && buttons.length > 0) {
      buttons.forEach(button => {
        button.removeEventListener('click', onInfoCTA)
        button.addEventListener('click', onInfoCTA)
      })
    }
  }

/*
  onMarkerHover(marker, property) {
    const {
      id,
    } = property
    new Image().src = `/assets/map/${id}.jpg`
  }
*/

  onMarkerClick(marker, property) {
    const {onInfoCTA} = this.props

    if(this.infoWindow) this.infoWindow.close()

    analyticsEvent('properties_map', 'map marker click', property.name)

    const content = ReactDOMServer.renderToString(
      <InfoWindow property={property}/>
    )
    this.infoWindow.setContent(content)
    this.infoWindow.open({
      anchor: marker,
      map: this.map,
      shouldFocus: true,
    })

    if(onInfoCTA) setTimeout(this.setupInfoWindowListeners, 500)
  }

  showHideMarkers() {
    this.bounds = this.map.getBounds()
    this.markers.forEach(marker => {
      const inBounds = this.bounds && this.bounds.contains(marker.getPosition())
      marker.setVisible(inBounds)
    })
  }

  renderMarkers() {
    const {
      propertiesFiltered,
      autoFitBounds,
      enableInfo,
    } = this.props

    const fitBounds = autoFitBounds && Boolean(propertiesFiltered.length > 0)

    const markerImage = new this.google.maps.MarkerImage('/assets/images/marker.png', new this.google.maps.Size(100, 30))

    const self = this
    // this.bounds = this.map.getBounds()
    // console.log('BOUNDS', this.bounds)

    this.markers = propertiesFiltered.map((p, idx) => {
      const {
        name,
        latitude,
        longitude,
      } = p

      const position = new this.google.maps.LatLng(latitude, longitude)


      const marker = new this.google.maps.Marker({
        map: this.map,
        clickable: enableInfo,
        position: position,
        zIndex: idx,
        label: {
          text: name,
          className: styles.markerLabel,
        },
        /*icon: {
          path: 'M-50 0 L50 0 L50 30 L-50 30Z',
          strokeColor: 'transparent'
        },
        */
        icon: markerImage,
      })

      if(fitBounds) {
        self.bounds.extend(position)
      }

      if(enableInfo) {
        marker.addListener('click', () => this.onMarkerClick(marker, p))
      }

      return marker
    })
      .filter(m => m)

    // console.log('renderMarkers', this.markers.length)

    if(fitBounds) this.map.fitBounds(this.bounds)

    this.google.maps.event.addListener(this.map, 'click', () => {
      if(this.infoWindow) this.infoWindow.close()
    })
  }

  renderClusters() {
    // console.log('renderClusters')
    const styleClusters = [
      {
        width: 36,
        height: 36,
      },
      {
        width: 40,
        height: 40,
      },
      {
        width: 50,
        height: 50,
      }
    ]

    this.clusterer = new MarkerClusterer(this.map, this.markers, {
      styles: styleClusters,
      clusterClass: styles.clusterIcon,
      gridSize: 60,
      maxZoom: 13,
    })

    this.google.maps.event.addListener(this.map, 'bounds_changed', () => {
      // console.log('bounds_changed')
      this.showHideMarkers()
    })
  }

  renderMap() {
    // console.log('renderMap')
    const {
      propertiesFiltered,
      zoom,
      autoFitBounds,
      centerLatLng,
      enableFullscreen,
    } = this.props

    if(!this.google || !propertiesFiltered) {
      // console.log('ERROR: No propertiesFiltered, or google maps not loaded', 'propertiesFiltered', Boolean(propertiesFiltered), 'google', this.google)
      return
    }

    const numProperties = propertiesFiltered.length
    // const single = numProperties === 1
    const fitBounds = autoFitBounds && Boolean(numProperties > 0)

    const mapOptions = {
      center: centerLatLng,
      zoom: zoom || 5,
      maxZoom: 20,
      fullscreenControl: enableFullscreen,
      gestureHandling: 'greedy',
      scrollwheel: true,
      styles: MAP_STYLES,
      streetViewControl: false,
      mapTypeControlOptions: {
        mapTypeIds: []
      },
    }

    this.map = new this.google.maps.Map(this.mapNode, mapOptions)
    this.infoWindow = new this.google.maps.InfoWindow({content: ''})
    this.bounds = new this.google.maps.LatLngBounds()

    this.renderMarkers(fitBounds)
    this.renderClusters(fitBounds)

    if(!fitBounds && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(fitBounds) return
        const initialLocation = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
        this.map.setCenter(initialLocation)
        this.map.setZoom(9)
      })
    }

    /*if(fitBounds) {
      this.map.fitBounds(this.bounds)
    } else {
      if(single) {
        const {latitude, longitude} = propertiesFiltered[0]
        if(latitude && longitude) {
          const latlng = new this.google.maps.LatLng(latitude, longitude)
          this.map.setCenter(latlng)
          this.map.setZoom(14)
        } else {
          this.map.setZoom(zoom)
        }
      } else {
        this.map.setZoom(zoom)
      }
    }*/
  }

  render() {
    const {
      propertiesFiltered,
      className
    } = this.props

    if(!propertiesFiltered) return (
      <div className={styles.loader}>
        <LoaderSpinner/>
      </div>
    )

    return (
      <div
        ref={n => this.mapNode = n}
        className={`${styles.Map} ${className ? className : ''}`}
      />
    )
  }
}

export default withRouter(Map)
