import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {fetchEntries} from '../services/service-contentful'
import {StateProvider} from '../StateContext'
import Layout from './Layout'
import {getCookie, setCookie} from '../services/service-cookies'
import {COOKIES} from '../constants/consts-cookies'
import {setLockedViewport} from '../services/service-utils'
import  * as msal from '@azure/msal-browser' 

class App extends Component {
  constructor(props) {
    super(props)

    this.closeOnBoarding = this.closeOnBoarding.bind(this)

    this.authenticateMSAL = this.authenticateMSAL.bind(this);

    this.state = {
      page: null,
      user: null,
      authenticating: true,
      authenticated: false,
      unauthorized: false,
      expired: false,
      showLoginScreen: false,
      showOnBoarding: false,
    }
  }

  componentDidMount() {
    this.loadPageData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {


    const pathnamePrev = prevProps.location.pathname
    const {pathname} = this.props.location
    const isNewRoute = pathnamePrev !== pathname
    // console.log('isNewRoute', isNewRoute, pathnamePrev, pathname)

    if(isNewRoute) {
      this.setState({
        page: null
      })

      this.loadPageData()
    }

  }

  checkOnBoarding() {
    const onboarded = getCookie(COOKIES.nrep_onboarded)
    if(!onboarded) {
      setTimeout(() => {
        this.setState({
          showOnBoarding: true
        })
      }, 500)
    }
  }

  closeOnBoarding = () => {
    setCookie(COOKIES.nrep_onboarded, true)
    setLockedViewport(false)

    const onboarded = getCookie(COOKIES.nrep_onboarded)
    console.log("closeOnBoarding", onboarded)

    this.setState({
      showOnBoarding: false
    })
  }


  async loadPageData() {
    const {pathname} = window.location
    // console.log('pathname', pathname)
    const slug = pathname === '/' ? pathname : pathname.substr(1).split('/')[0]
    // console.log('slug', slug)

    const res = await fetchEntries('page', slug)
    // console.log('loadPageData', res)
    if(res && res.fields) {
      this.setState({
        page: res.fields
      }, () => {
        window.scrollTo(0, 0)

        this.authenticateMSAL();
      })
    }
  }

  authenticateMSAL()
  {
    const config = {
      auth: {
        clientId: 'fa2be296-99e6-44fc-90d0-8705b0893152', 
        authority: 'https://login.microsoftonline.com/organizations',
        redirectUri: '/'
      },
      cache: {
        cacheLocation: "localStorage",
        storageAuthStateInCookie: false
      },
      system: {
        loggerOptions: {
          loggerCallback: (Level, message, containsPII) => {
            /* console.log(message); */
          },
          logLevel: "Error"
        }
      }
    }

    this.msalObj = new msal.PublicClientApplication(config);

    this.msalObj.handleRedirectPromise().then((response) => 
    {
      if(response)
      {
        this.setState({
          user: response.account.username,
          authenticating: false,
          authenticated: true,
          unauthorized: false,
          expired: false,
          showLoginScreen: false,
        })
      } 
      else
      {
        // Check cache
        this.selectAccount();
      }

    })
    .catch((error) => 
    {
      console.log(error);
    })
  }

  selectAccount()
  {
    const accounts = this.msalObj.getAllAccounts();

    const options = {
      scopes: ['user.read']
    }

    if(accounts.length === 0 || accounts.length > 1)
    {  
      this.setState({
        showLoginScreen: false,
        unauthorized: true
      })

      this.msalObj.loginRedirect(options);
      return;
    }
    else if(accounts.length === 1)
    {
      const account = accounts[0];

      this.setState({
        user: account.username,
        authenticating: false,
        authenticated: true,
        unauthorized: false,
        expired: false,
        showLoginScreen: false,
      })
    }
  }

  render() {
    const {
      user,
      authenticating,
      authenticated,
      unauthorized,
      expired,
      showLoginScreen,
      showOnBoarding,
      page,
    } = this.state

    return (
      <StateProvider value={this.state}>
        <Layout
          {...this.props}
          user={user}
          authenticating={authenticating}
          authenticated={authenticated}
          unauthorized={unauthorized}
          expired={expired}
          showLoginScreen={showLoginScreen}
          showOnBoarding={showOnBoarding}
          closeOnBoarding={this.closeOnBoarding}
          page={page}
        />
      </StateProvider>
    )
  }
}

export default withRouter(App)
