import React from 'react'
import styles from './PropertyTags.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import {getStatus} from "../../services/service-utils"
// import InnerHTML from "dangerously-set-html-content"

export default function PropertyTags({data}) {
  if(!data) return null
  const {
    name,
    segment,
    status,
    address,
    postalCode,
    city,
    country,
    exitDate,
  } = data

  const statusOverride = getStatus(exitDate, status)

  return (
    <div className={styles.PropertyTags}>
      {segment &&
      <div className={styles.platform}>
        <Svg id={ICONS.house}/>
        <span>{segment}</span>
      </div>
      }
      <div className={styles.platform}>
        <span className={`dot ${statusOverride}`}/>
        <span>{statusOverride}</span>
      </div>
      {address &&
      <div className={styles.platform}>
        <Svg id={ICONS.pin}/>
        <span>
        <address>
          <strong>{name}</strong>
          {/*<InnerHTML html={address.replaceAll('\n', '<br/>')} />*/}
          {address && <div>{address}</div>}
          <div>
            {postalCode && <span>{postalCode}</span>}
            {postalCode && city && <span>&nbsp;</span>}
            {city && <span>{city}</span>}
          </div>
          {country && <div>{country}</div>}

        </address>
        </span>
      </div>
      }
    </div>
  )
}
