import React, {Component} from 'react'
import styles from './ModalProperty.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import Modal from '../Modal/Modal'
import Property from "../Property/Property"

class ModalProperty extends Component {
  constructor(props) {
    super(props)

    this.beforeClose = this.beforeClose.bind(this)
    this.setIndex = this.setIndex.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onSkip = this.onSkip.bind(this)

    this.state = {
      data: null,
      show: false,
      index: 0,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    const {propertyId, propertiesFormatted} = this.props
    // console.log('propertyId', propertyId)

    let data = propertiesFormatted.find(p => p.id === propertyId)
    if(!data) {
      data = propertiesFormatted.find(p => encodeURIComponent(p.name.toLowerCase()) === propertyId)
    }
    // console.log('data', data)

    this.setState({
      data: data,
    }, () => {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 50)
    })
  }

  beforeClose() {
    this.setState({
      show: false
    }, () => {
      const {onClose} = this.props
      if(onClose) setTimeout(onClose, 300)
    })
  }

  setIndex(index) {
    this.setState({
      index: index
    })
  }

  onNext() {
    const {data, index} = this.state
    const {screens} = data.fields
    const numItems = screens.length

    const nextIndex = index + 1
    if(nextIndex >= numItems) {
      this.beforeClose()
    }

    this.setState({
      index: nextIndex
    })
  }

  onSkip() {
    this.beforeClose()
  }

  render() {
    const {propertyId} = this.props

    const {
      show,
      data,
    } = this.state

    if(!data) return null

    return (
      <Modal onClose={this.beforeClose} propertyId={propertyId}>
        <div className={show ? styles.show : styles.ModalProperty}>
          <button disabled className={styles.bgClose} onClick={this.beforeClose}/>

          <div className={styles.background}>

            <div className={styles.content}>
              <Property data={data}/>
            </div>

            <button onClick={this.beforeClose} className={styles.buttonClose}>
              <Svg id={ICONS.plus}/>
            </button>

          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalProperty
