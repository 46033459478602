import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import styles from './Page.module.css'
import ComponentMapper from '../components/ComponentMapper'

class Page extends Component {
  render() {
    const {page, user} = this.props
    if(!page) return null

    const {content, slug} = page
    const isFrontPage = slug === '/'

    const mapSectionData = content.find(d => d.sys.contentType.sys.id === 'propertyMap')

    return (
      <div id="page" className={styles.Page}>
        {isFrontPage && mapSectionData &&
          <ComponentMapper data={mapSectionData} user={user}/>
        }

        {!isFrontPage && content &&
        content.map((d, idx) => <ComponentMapper key={idx} data={d} user={user}/>)
        }
      </div>
    )
  }
}

export default withRouter(Page)
