import React, {useState} from 'react'
import styles from './PropertyHeader.module.css'
import PropertyTags from '../PropertyTags/PropertyTags'
import ImageGalleryOverlay from "../ImageGalleryOverlay/ImageGalleryOverlay"

export default function PropertyHeader({data}) {
  const {
    id,
    name,
    /*
    address,
    postalCode,
    city,
    country,
    */
    latitude,
    longitude,
    image,
  } = data

  const [showGallery, setShowGallery] = useState(false)

  const imageUrl = `/assets/map/${id}.jpg`

  const destination = `${latitude},${longitude}`
  // console.log('destination', destination)

  return (
    <section className={styles.PropertyHeader}>
      <div className="container">
        <div className="row">

          <div className="col-xs-12 col-md-6">
            {(image || imageUrl) &&
              <div
                className={styles.imageWrapper}
                /*onClick={() => setShowGallery(true)}*/
              >
                <div
                  style={{backgroundImage: `url(${imageUrl})`}}
                  className={styles.image}
                />
              </div>
            }
          </div>
          <div className="col-xs-12 col-md-6">
            {name &&
              <h1 className={styles.title}>{name}</h1>
            }
            {name &&
              <PropertyTags
                data={data}
                className={styles.tags}
                horizontal={true}
              />
            }
            <a
              href={`https://www.google.com/maps/dir/?api=1&origin=&destination=${destination}`}
              target="_blank"
              rel="noreferrer"
              className="button blue"
              style={{margin: '2rem 0'}}
            >
              SHOW THE WAY
            </a>
          </div>

        </div>
      </div>

      {showGallery &&
        <ImageGalleryOverlay
          data={data}
          setShow={setShowGallery}
        />
      }
    </section>
  )
}
